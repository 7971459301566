.textarea {
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.32);
  border-radius: 4px;
  padding: 8px 12px;
  resize: none;
  color: #4A4A4A;
  font-style: normal;
  font-family: 'Inter';
  font-size: 20px;
  font-weight: 300;
  line-height: 24px;
  background-color: transparent;

  &:focus {
    // border: 1px solid black;
    outline: none;
  }

  &:focus-visible {
    // border: 1px solid black;
    outline: none;
  }

  &::placeholder {
    color: #4A4A4A;
    font-size: 20px;
    font-family: 'Inter';
    font-weight: 300;
    line-height: 24px;
  }
}

.input-invalid {
  border: 1px solid #ea174a;
}

.input-invalid {
  border: 1px solid #ea174a;
}

.label {
  display: block;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  font-family: 'Inter';
  line-height: 24px;
  color: #0F0F0F;
  margin-bottom: 8px;
}

.error-text {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17.81px;
  color: #9B2626;
  margin: 0;
  margin-top: 5px;
  text-align: left;
  font-family: 'Inter'
}


@media (min-width: 320px) and  (max-width:639px) {
  .label {
    display: block;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    font-family: 'Inter';
    line-height: 16.8px;
    color: #0F0F0F;
    margin-bottom: 8px;
  }
  .input {
    width: 100%;
    height: 56px;
    // max-width: 544px;
    border: 1px solid rgba(0, 0, 0, 0.32);
    border-radius: 5px;
    padding: 8px 12px;
    font-style: normal;
    font-family: 'Inter';
    font-size: 20px;
    font-weight: 300;
    line-height: 24px;
    outline: none;
    color: #4A4A4A;
    background-color: transparent;
  
    &:focus {
      // border: 1px solid black;
    }
    &:focus-visible {
      // border: 1px solid black;
      outline: none;
    }
  
    &::placeholder {
      color: #4A4A4A;
      font-size: 14px;
      font-family: 'Inter';
      font-weight: 300;
      line-height: 16.8px;
    }
  }
}