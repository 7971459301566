.label {
  display: block;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  font-family: 'Inter';
  line-height: 18px;
  color: black;
  margin-bottom: 8px;
}

.error-text {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17.81px;
  color: #9B2626;
  margin: 0;
  margin-top: 5px;
  text-align: left;
  font-family: 'Inter'
}
