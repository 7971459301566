.about-us-container {
    width: calc(100% - 330px) !important;
    max-width: 100%;
  }

  .founder-sections {
    width: calc(100% - 350px);
    max-width: 100%;
  }
  
  @media (min-width: 640px) and (max-width: 1100px) {
    .about-us-container {
      width: 100% !important;
    }
    .founder-sections {
        width: 100%;
    }
  }
  @media (max-width: 640px) {
    .about-us-container {
      width: 100% !important;
    }
    .founder-sections {
        width: 100%;
    }
  }