.error-text {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17.81px;
    color: #9B2626;
    margin: 0;
    margin-top: 5px;
    text-align: left;
    font-family: 'Inter'
  }

  .input-disabled {
    // @extend .input;
    background-color: rgba(0, 0, 0, 0.08);
    color: rgba(0, 0, 0, 0.48);
    margin: 0px !important;
    margin-bottom: 4px !important;
    pointer-events: none;
  }