.control-panel {
  // margin-left: 16px;
}

.validation-rules {
  display: flex;
  align-items: center;

  p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #7e8180;
    margin-top: 4px;
  }
}

.updating-logo {
  display: flex;
  justify-content: space-between;
}

.updating-panel {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 170px;
}

.replase-btn {
  max-width: 100px;
}

.delete-btn {
  display: flex;
  align-items: flex-end;
  span {
    color: #EA174A;
    font-family: 'Inter';
    font-size: 14px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-left: 8px;
  }
}

.delte-icon {
  width: 16px;
  height: 16px;
}
