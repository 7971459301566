.upload-img {
  margin-top: 8px;
  position: relative;
  label {
    display: flex;
    gap: 8px;
    align-items: center;
    padding: 0;
    font-family: 'Inter';
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.1px;
    color: #000;
    cursor: pointer;
  }
  input {
    display: none;
  }
}

.upload-img .error {
  position: absolute;
  color: red;
  margin: 0;
  font-size: 12px;
  line-height: 14px;
  font-family: 'Inter';
  top: 25px;
  min-width: 150px;
}
