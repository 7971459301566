

$red: #9B2626;


body {
    background-color: #fff;
    font-family: "Inter", sans-serif;
}

*{
    background-repeat: no-repeat;
    box-sizing: border-box;
}
html {
    scroll-behavior: smooth;
  }
.container{
    max-width: 1600px;
    margin: 0 auto;
    padding: 0 38px;
    @media screen and (max-width: 1600px){
        width: 100%;
        padding: 0 38px;
    }

    @media (max-width: 992px) {
        padding: 0 16px;
    }

    @media (max-width: 640px) {
        padding: 0 8px;
    }
}

.whatsapp{
    position: fixed;
    right: 0;
    bottom: -7px;
    z-index: 20;
}

.homePage {

    .main_section{
        width: 100%;
        max-height: 100vh;
    
        overflow: hidden;
        position: relative;
    
        .mainimg{
            width: 100%;
        }
        .mainimg_mob{
            display: none;
        }
    
    
    
        &__wrap{
            position: absolute;
            margin-top: 106px;
            left: 0;
            right: 0;
            top: 0;
            height: 100%;
            width: 100%;
            left: 0;
            right: 0;
        }
    
        &__content{
            height: calc(100% - 106px);
            padding-top: 50px;
            position: relative;
            .container{
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
            }
        }
        
    
    
        .title{
            font-weight: 600;
            font-size: 44px;
            line-height: 170%;
            letter-spacing: 0.05em;
            text-transform: uppercase;
            text-align: center;
            color: #000;
        
            span{
                color: #fff;
                background-color: $red;
                padding: 7px 7px;
                border-radius: 8px;
        
            }
        }
    
        &__bottom{
            padding-bottom: 53px;
        }
        &__lblsss{
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 20px;
        }
        &__lbl{
            display: flex;
            align-items: center;
            font-weight: 500;
            font-size: 16px;
            color: #000;
            padding: 18px 20px;
            height: 56px;
            border-radius: 20px;
            box-shadow: 0 4px 44px 0 rgba(0, 0, 0, 0.3);
            background: #EFE8DF;
            gap: 10px;
            img{
                width: 24px;
            }
        }
    }
    
    .header{
        position: absolute;
        height: 106px;
        width: 100%;
        top: 0;
        border-bottom: 1px solid rgba(74, 74, 74, 0.3);
        .container{
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    
        .logo{
            img{
                width: 150px;
            }
        }
    }
    
    .main_menu{
        ul{
            display: flex;
            gap: 60px;
            li{
                a{
                    font-weight: 400;
                    font-size: 20px;
                    text-transform: uppercase;
                    color: #000;
                }
            }
        }
    }
    
    .burger-menu{
        display: none;
    }
    
    
    @media (max-width: 1280px) {
    
        .whatsapp{
            bottom: -32px;
            right: -23px;
        }
    
        .main_section{
            &__content{
                padding-top: 30px;
                height: calc(100% - 80px);
            }
    
            &__wrap{
                margin-top: 80px;
                .container{
                    min-height: 100%;
                }
            }
    
            .title{
                font-size: 32px;
            }
    
            &__bottom{
                padding-bottom: 30px;  
            }
        }
    
        .header{
            height: 80px;
        }
    }
    
    .main_menu_logo{
        display: none;
    }
    
    .main_menu{
        .burger-menu{
         z-index: 100;
        }
    }
    
    
    @media (max-width: 992px) {
    
        .whatsapp{
            svg{
                width: 110px;
            }
            bottom: -60px;
            right: -23px;
        }
        .main_section{
            &__content{
                padding-top: 28px;
                height: calc(100% - 63px);
            }
    
            &__wrap{
                margin-top: 63px;
                .container{
                    min-height: 100%;
                }
            }
    
            .title{
                font-size: 24px;
                span{
                    padding: 4px;
                    border-radius: 4px;
                }
            }
    
            &__bottom{
                padding-bottom: 15px;  
            }
    
            &__lblsss{
                gap: 8px;
            }
            &__lbl{
                font-size: 10px;
                padding: 5px 17px;
                height: 32px;
                border-radius: 8px;
                gap: 4px;
                img{
                    width: 9.6px;
                }
            }
        }
    
        .header{
            height: 63px;
    
            .logo{
                img{
                    width: 109px;
                }
            }
    
            .main_menu{
                position: fixed;
                opacity: 0;
                visibility: hidden;
                transition: 0.23s;
                width: 100vw;
                height: 100vh;
                background-color: #fff;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                z-index: 50;
                
                .burger-menu{
                    display: flex;
                    position: absolute;
                    top: 20px;
                    right: 20px;
                }
    
                .main_menu_logo{
                    display: flex;
                    width: 100px;
                    margin: 0 auto;
                    justify-content: center;
                    margin-top: 20px;
                    img{
                        width: 140px;
                    }
                }
    
                ul{
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    height: 100%;
                    gap: 40px;
    
                    margin-top: -30px;
                    li{
                        a{
                            font-size: 16px;
                        }
                    }
                }
            }
            .main_menu_show{
                opacity: 1;
                visibility: visible;
                transition: 300ms;
            }
        }
       
        .burger-menu {
            width: 25px;
            height: 13px;
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-end;
            cursor: pointer;
            z-index: 100;
        
            span {
                display: block;
                width: 100%;
                height: 1px;
                background-color: black;
                transition: transform 0.3s ease, opacity 0.2s ease, width 0.3s ease;
        
                &:nth-child(2) {
                    width: 14px;
                }
            }
        
            &.active {
                span:nth-child(1) {
                    transform: translateY(6px) rotate(45deg);
                    width: 100%;
                }
                span:nth-child(2) {
                    opacity: 0;
                }
                span:nth-child(3) {
                    transform: translateY(-6px) rotate(-45deg);
                    width: 100%;
                }
            }
        }
        
        
    
    }
    
    .speek{
        display: none;
    }
    
    .logo_mob{
        display: none;
    }
    
    @media (max-width: 640px) {
    
        .logo_desc{
            display: none;
        }
    
        .logo_mob{
            display: flex;
        }
    
        .whatsapp{
            svg{
                width: 120px;
            }
            bottom: -55px;
            right: -27px;
        }
        .main_section{
            max-height: 100svh !important;
            min-height: 400px !important;
            height: 100vh;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            .mainimg{
                bottom: 0;
                position: absolute;
            }
            .mainimg_desc{
                display: none;
            }
            .mainimg_mob{
                display: block;
                width: 100%;
                object-fit: cover;
                height: 100%;
            }
            &__content{
                padding-top: 45px;
                height: calc(100% - 31px);
            }
    
            &__wrap{
                margin-top: 31px;
                .container{
                    min-height: 100%;
                }
            }
    
            .title{
                font-size: 24px;
                color: #fff;
                max-width: 330px;
                span{
                    padding: 2px;
                    border-radius: 4px;
                }
            }
    
            &__bottom{
                padding-bottom: 55px;  
            }
    
            &__lblsss{
                gap: 4px;
            }
            &__lbl{
                font-size: 10px;
                padding: 7px 6px;
                height: 24px;
                border-radius: 4px;
                background: transparent;
                border: 0.7px solid #EFE8DF;
                // box-shadow: none;
                text-align: center;
                color: #EFE8DF;
                img{
                    width: 13px;
                }
                &:nth-child(1){
                    border-left: 0.7px solid #EFE8DF !important;
                }
                &:nth-child(2){
                    gap: 3.4px;
                }
            }
        }
    
        .flxcol{
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .speek{
            display: block;
            background-color: #EFE8DF;
            padding: 6px 10px;
            border-radius: 4.3px;
            margin-top: 14px;
            font-size: 12px;
            color: #000000;
            font-weight: 600;
        }
    
        .header{
            height: 31px;
            margin-top: 17px;
            border-bottom: none;
    
            .logo{
                img{
                    width: 100px;
                }
            }
    
            .main_menu{
    
                .burger-menu{
                    top: 22px;
                    right: 20px;
                }
    
                .main_menu_logo{
    
                    margin-top: 20px;
                    img{
                        width: 90px;
                    }
                }
    
                ul{
                    gap: 40px;
                    margin-top: -60px;
                    li{
                        a{
                            font-size: 12px;
                        }
                    }
                }
            }
    
        }
       
        .burger-menu {
            width: 24px;
            height: 14px;
            span {
                transition: transform 0.3s ease, opacity 0.2s ease, width 0.3s ease;
                background-color: #fff;
                &:nth-child(2) {
                    width: 12px;
                }
            }
        
            &.active {
                span{
                    background-color: #000;
                }
                span:nth-child(1) {
                    transform: translateY(6px) rotate(45deg);
                }
                span:nth-child(2) {
                }
                span:nth-child(3) {
                    transform: translateY(-6px) rotate(-45deg);
                }
            }
        }
        
        
    
    }
    
    
    
    
    // feedback
    
    
    .title_2{
        font-weight: 600;
        font-size: 32px;
        line-height: 118%;
        text-transform: uppercase;
        text-align: center;
        color: #0f0f0f;
        margin-bottom: 44px;
    }
    
    .feedback{
        padding: 50px 0 63px 0;
        overflow: hidden;
    
        position: relative;
    
        .container{
            position: relative;
        }
    
        .services__wrap{
            // width: 100%;
            width: 100vw;
        }
    
        .feedback_slider{
            overflow: visible;
        }
        .swiper-wrapper{
            width: 100vw;
        }
        .swiper-slide{
            // width: 30%;
            // min-width: 400px;
            // max-width: 489px;
            // width: 489px;
        }
    }
    
    
    .feedback_item{
        height: 352px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    
        background: #fff;
        border: 1px solid #dbdbdb;
        border-radius: 12px;
        padding: 32px;    
    
        &__top{
            img{
                width: 60px;
                margin-bottom: 20px;
            }
    
            p{
                font-weight: 400;
                font-size: 16px;
                line-height: 150%;
                color: #4A4A4A;
            }
        }
    
        &__bottom{
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
        }
    
        &__user{
            display: flex;
            align-items: center;
        }   
        &__img{
            margin-right: 24px;
            min-width: 80px;
            width: 80px;
            min-height: 80px;
            max-height: 80px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 100%;
            overflow: hidden;
            img{
                width: 105%;
                height: 105%;
                object-fit: cover;
                object-position: center center;
    
            }
        }
    
        &__user_info{
            span{
                display: inline-block;
                margin-bottom: 5px;
                font-weight: 500;
                font-size: 16px;
                color: #0f0f0f;
            }
            p{
                margin-bottom: 5px;
                font-weight: 400;
                font-size: 14px;
                color: #0f0f0f;
                &:last-child{
                    margin-bottom: 0;
                }
            }
        }
    
        &__rating{
            padding-top: 8px;   
        }
    
    }
    
    
    
    
    .pagination{
        display: flex;
        justify-content: center;
        margin-top: 44px;
        gap: 10px;
        .swiper-pagination-bullet{
            margin-left: 0px !important;
            margin-right: 0px !important;
    
            border-radius: 100px;
            width: 30px;
            height: 10px;
            background: #dbdbdb;
            opacity: 1;        
            transition: 0.33s;
        }
    
        .swiper-pagination-bullet-active{
            background: #8f302b;
            width: 100px;
            transition: 0.33s;
        }
    }
    
    
    
    
    @media (max-width: 992px) {
        .title_2{
            font-size: 24px;
            line-height: 118%;
            margin-bottom: 32px;
        }
        .feedback{
            padding: 80px 0 50px 0;
            .swiper-slide{
                width: 30%;
                min-width: 489px;
            }
        }    
    
        .feedback_item{
            height: 272px;
            border-radius: 16px; 
        
            &__top{
                img{
                    width: 44px;
                    margin-bottom: 12px;
                }
                p{
                    font-size: 14px;
                }
            }
        
            &__user_info{
                span{
                    font-size: 14px;
                }
                p{
                    font-size: 12px;
                }
            }
        
            &__rating{
                padding-top: 11px;   
            }
        
        }
        .pagination{
            gap: 10px;
            .swiper-pagination-bullet{
                width: 30px;
                height: 10px;
            }
        
            .swiper-pagination-bullet-active{
                width: 100px;
            }
        }
    }
    
    
    @media (max-width: 640px) {
        .title_2{
            font-size: 18px;
            margin-bottom: 20px;
        }
        .feedback{
            padding: 44px 0 34px 0;
            .swiper-slide{
                width: 244px;
                min-width: 244px;
            }
        }    
    
        .feedback_item{
            height: 193px;
            border-radius: 10px; 
            padding: 19px;
    
        
            &__top{
                img{
                    width: 20px;
                    margin-bottom: 7px;
                }
                p{
                    font-size: 10px;
                }
            }
            &__img{
                margin-right: 14px;
                min-width: 36px;
                width: 36px;
                min-height: 36px;
                max-height: 36px;
            }
            &__user_info{
                span{
                    font-size: 8px;
                    margin-bottom: 3px;
                }
                p{
                    font-size: 7px;
                    margin-bottom: 3px;
                }
            }
        
            &__rating{
                padding-top: 2px; 
                display: flex;
                gap: 2px;  
                img{
                    width: 11px;
                }
            }
        
        }
    
        .pagination{
            margin-top: 20px;
            gap: 6px;
            .swiper-pagination-bullet{
                width: 17px;
                height: 6px;
            }
        
            .swiper-pagination-bullet-active{
                width: 55px;
            }
        }
    }
    
    
    
    
    
    
    
    
    .services{
    
        overflow: hidden;
    
        
        padding: 60px 0 126px 0;
        
        @media (min-width: 992px) {
            .swiper-wrapper{
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                gap: 44px 32px;
            }
        }
    
        .swiper-slide{
            width: 262px;
    
            &:nth-child(1){
                margin-left: 230px;
            }
            &:nth-child(3){
                margin-right: 230px;
            }
    
            @media (max-width: 1400px) {
                &:nth-child(1){
                    margin-left: 140px;
                }
                &:nth-child(3){
                    margin-right: 140px;
                }
            }
            @media (max-width: 1225px) {
                &:nth-child(1){
                    margin-left: 0px;
                }
                &:nth-child(3){
                    margin-right: 0px;
                }
            }
        }
    
        .services_item{
            &__img{
                width: 100%;
                border-radius: 30px;
                overflow: hidden;
                img{
                    width: 100%;
                    height: 262px;
                    object-fit: cover;
                }
            }
    
            &__title{
                text-align: center;
                margin-top: 37px;
                font-weight: 500;
                font-size: 24px;
                text-align: center;
                color: #000;
            }
        }
    
        &__txt{
            margin-top: 60px;
            text-align: center;
            font-weight: 600;
            font-size: 20px;
            line-height: 120%;
            text-align: center;
            color: #4A4A4A;
            span{
                color: #7aa1c4;
            }
        }
    
        .pagination{
            display: none;
        }
    }
    
    
    
    @media (max-width: 992px) {
        .services{
        
            padding: 50px 0 100px 0;
    
    
            &__wrap{
                width: 100vw;
                margin-left: -16px;
            }
    
            .swiper-slide{
                width: auto;
            }
    
            .services_item{
                &__img{
                    width: 100%;
                    border-radius: 23px;
                    width: 200px;
                    height: 200px;
                    img{
                        width: 100%;
                        height: 100%;
                    }
                }
        
                &__title{
                    margin-top: 37px;
                    font-size: 20px;
                }
            }
    
            .services_pagination{
                margin-top: 41px;
            }
    
    
            &__txt{
                font-size: 16px;
            }
    
    
            .pagination{
                display: flex;
            }
        }
    }
    
    
    
    @media (max-width: 640px) {
        .services{
        
            padding: 34px 0 67px 0;
    
            &__wrap{
                width: calc(100vw - 8px);
                margin-left: 0px;
            }
    
            .swiper-slide{
                width: auto;
            }
    
            .services_item{
                &__img{
                    width: 100%;
                    border-radius: 12px;
                    width: 110px;
                    height: 110px;
                }
        
                &__title{
                    margin-top: 20px;
                    font-size: 10px;
                }
            }
    
            .services_pagination{
                margin-top: 20px;
            }
    
    
            &__txt{
                margin-top: 20px;
                font-size: 10px;
            }
        }
    }
    
    
    
    
    
    
    
    
    
    
    .title_2_1{
        font-weight: 600;
        font-size: 32px;
        line-height: 118%;
        text-transform: uppercase;
        text-align: center;
        color: #fff;
        margin-bottom: 44px;
    }
    
    .creme{
        padding: 116px 0;
        background-color: #9B2626;
    
    
        .swiper-slide{
            display: flex;
            justify-content: center;
        }
    
        .creme_pagination{
            .swiper-pagination-bullet{
                background: #EFE8DF;
            }
            .swiper-pagination-bullet-active{
                background: #e2bdd4;
            }
        }
    
    }
    
    
    .creme_item{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 157px;
        height: 157px;
        background: #fff;
        border-radius: 83px;
        img{
            // width: auto;
            max-height: 70px;
            max-width: 80%;
        }
    }
    
    @media (max-width: 992px) {
        .title_2_1{
            font-size: 24px;
        }
        
        .creme{
            overflow: hidden;
            padding: 68px 0;
    
            .creme_slider,.swiper-wrapper{
                overflow: visible;
            }
        }
    
        .creme_item{
            width: 130px;
            height: 130px;
            img{
                max-width: 100px;
                max-height: 60px;
            }
        }
    }
    @media (max-width: 640px) {
        .title_2_1{
            font-size: 18px;
            margin-bottom: 22px;
        }
        
        .creme{
            padding: 35px 0;
    
            .creme_pagination{
                margin-top: 22px;
            }
        }
    
        .creme_item{
            width: 65px;
            height: 65px;
            img{
                max-width: 45px;
                max-height: 30px;
            }
        }
    }
    
    
    
    
    
    
    
    
    
    
    
    
    .why_us{
        padding: 126px 0 63px 0;
    
        &__list{
            display: flex;
            gap: 20px;
        }
    
    }
    .why_us_item{
        width: 25%;
    
        box-shadow: 0 5px 0 0 rgba(0, 0, 0, 0.15);
        background: #fff;
        border-left: 1px solid #dbdbdb;
        border-right: 1px solid #dbdbdb;
        border-top: 1px solid #dbdbdb;
        border-radius: 20px;
        padding: 24px;
    
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    
        &__ic{
            margin-bottom: 24px;
            background-color: #EFE8DF;
            border-radius: 12px;
            width: 69px;
            height: 65px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    
        &__title{
            font-weight: 500;
            font-size: 22px;
            color: #000;
            margin-bottom: 11px;
        }
    
        &__txt{
            font-weight: 400;
            font-size: 18px;
            line-height: 167%;
            color: #4A4A4A;
        }
    }
    
    
    @media (max-width: 1200px) {
        .why_us_item{
            padding: 24px 16px;
    
            &__title{
                font-size: 20px;
            }
        
            &__txt{
                font-size: 16px;
            }
        }
    }
    
    @media (max-width: 992px) {
            
        .why_us{
            padding: 100px 0 50px 0;
    
            &__list{
                flex-direction: column;
            }
    
        }
        .why_us_item{
            width: 100%;
            border-radius: 20px;
            padding: 24px;
    
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
    
            &__title{
                font-size: 22px;
            }
    
            &__txt{
                font-size: 18px;
            }
        }
    }
    
    @media (max-width: 640px) {
            
        .why_us{
            padding: 67px 0 25px 0;
            overflow: hidden;
    
            &__list{
                flex-direction: row;
                overflow: scroll;
                width: 100vw;
                padding-bottom: 15px;
                left: -8px;
                position: relative;
                padding-left: 8px;
                padding-right: 8px;
    
                overflow: auto;
                scrollbar-width: none;
                &::-webkit-scrollbar {
                    display: none; 
                }
            }
    
        }
        .why_us_item{
            min-width: 71vw;
            border-radius: 20px;
            padding: 24px 10px;
    
            &__ic{
                width: 52px;
                height: 50px;
                border-radius: 6px;
                margin-bottom: 24px;
                img{
                    width: 30px;
                }
            }
            &__title{
                font-size: 14px;
                font-weight: 500;
                margin-bottom: 11px;
                text-align: center;
            }
    
            &__txt{
                font-size: 12px;
                text-align: center;
            }
        }
    }
    
    
    
    
    
    
    strong{
        font-weight: 700;
    }
    
    
    .founder{
        padding: 63px 0;
    
        .founder_sec1{
            margin-bottom: 40px;
            background-image: url(../img/logobiig.svg);
            background-repeat: no-repeat;
            background-position: center;
            background-size: 100%;
        }
        &__photo{
            display: flex;
            justify-content: center;
            div{
                border-radius: 225px;
                width: 450px;
                height: 450px;
                overflow: hidden;
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }
        }
    
        &__text_sec{
            max-width: 727px;
            margin: 0 auto;
            p{
                font-weight: 400;
                font-size: 18px;
                line-height: 167%;
                text-align: center;
                color: #4A4A4A;
                a{
                    color: #4A4A4A;
                }
            }
        }
    }
    .readmore{
        display: none;
        color: #9B2626;
        font-weight: 700;
        font-size: 12px;
        line-height: 150%;
        text-align: center;
    }
    
    
    @media (max-width: 992px) {
        .founder{
            padding: 63px 0;
        
            .founder_sec1{
                margin-bottom: 24px;
            }
            &__photo{
                div{
                    width: 265px;
                    height: 265px;
                }
            }
        
            &__text_sec{
                max-width: 520px;
                p{
                    font-size: 16px;
                    line-height: 162%;
                }
            }
        }
    }
    
    
    
    @media (max-width: 640px) {
        .founder{
            padding: 33px 0 67px 0;
        
            .founder_sec1{
                margin-bottom: 12px;
            }
            &__photo{
                div{
                    width: 90px;
                    height: 90px;
                }
            }
        
            &__text_sec{
                padding-left: 30px;
                padding-right: 30px;
                p{
                    font-size: 12px;
                    line-height: 150%;
                }
            }
        }
        .moretext{
            display: none;
        }
        .readmore{
            width: 16px;
            display: inline-block;
        }
    }
    .moretext_show{
        display: inline;
    }
    
    
    
    
    
    
    
    .become{
    
        background-color: #9B2626;
        padding: 30px 0;
        overflow: hidden;
    
        .title_2{
            color: #FFFFFF;
        }
    
        .become_p{
            font-weight: 600;
            font-size: 18px;
            line-height: 133%;
            text-align: center;
            color: #EFE8DF;
            margin-top: 44px;
        }
    
        .swiper-wrapper{
            overflow: visible;
        }
        .become_slider{
            overflow: visible;
        }
    
    
        .pagination{
            .swiper-pagination-bullet{
                background: #EFE8DF;
            }
            .swiper-pagination-bullet-active{
                background: #e2bdd4;
            }
        }
    
    }
    
    
    
    .become_item_wrp{
        padding-top: 22px;
    }
    
    .become_item{
        border-left: 1px solid #dbdbdb;
        border-right: 1px solid #dbdbdb;
        border-top: 1px solid #dbdbdb;
        border-radius: 32px;
        padding: 24px;
        height: 274px;
        box-shadow: 0 5px 16px 0 rgba(0, 0, 0, 0.15);
        background: #fff;
    
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    
        position: relative;
    
        &::before{
            border-radius: 30px;
            width: 100%;
            height: 100%;
            display: block;
            content: '';
            position: absolute;
            top: -22px;
            right: -26px;
            background-color: #EFE8DF;
            z-index: -1;
            opacity: 0.2;
        }
    
        &__img{
            background: #EFE8DF;
            border-radius: 12px;
            width: 69px;
            height: 65px;
            margin-bottom: 32px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    
        &__title{
            font-weight: 500;
            font-size: 22px;
            text-align: center;
            color: #000;
        }
    }
    
    
    
    
    @media (max-width: 992px) {
    
        .become{
            padding-left: 80px;
            padding-right: 80px;
            
            .become_p{
                display: none;
            }
        
        }
        
    }
    
    
    
    @media (max-width: 640px) {
    
        .become{
            padding-top: 27px;
            padding-bottom:27px;
            padding-left: 0px;
            padding-right: 0px;
    
            .title_2{
                font-size: 18px;
            }
    
        }
    
        .become_item_wrp{
            padding-top: 15px;
        }
        .become_slider{
            padding-left: 40px !important;
            padding-right: 40px !important;
        }
        
        .become_item{
            border-radius: 16px;
            padding: 12px;
    
            height: 140px;
        
            &::before{
                border-radius: 15px;
                top: -11px;
                right: -12px;
            }
        
            &__img{
                border-radius: 6px;
                width: 34px;
                height: 32px;
                margin-bottom: 16px;
                img{
                    width: 20px;
                }
            }
        
            &__title{
                font-size: 12px;
                line-height: 130%;
            }
        }
        
    }
    
    
    
    
    
    
    .masha{
        padding: 126px 0 63px 0;
    
        .title_2{
            margin-bottom: 0;
        }
    
        .masha_slider{
            padding-top: 60px;
            padding-left: 7px;
            padding-right: 7px;
        }
    }
    .masha_slide_img{
        box-shadow: 0 3px 58px 0 rgba(0, 0, 0, 0.3);
        border-radius: 12px;
        overflow: hidden;
        img{
            width: 100%;
        }
    }
    
    
    @media (max-width: 992px) {
        .masha{
            padding-top: 100px;
            padding-bottom: 50px;
    
            overflow: hidden;
    
            .masha_slider,.swiper-wrapper{
                overflow: visible;
            }
    
            .title_2{
                margin-bottom: -20px !important;
            }
    
            .masha_slider{
                padding-left: 80px;
                padding-right: 80px;
            }
        }
    }
    
    @media (max-width: 640px) {
        .masha{
            padding-top: 67px;
            padding-bottom: 33px;
    
            overflow: hidden;
    
            .masha_slider,.swiper-wrapper{
                overflow: visible;
            }
    
            .title_2{
                margin-bottom: -10px !important;
            }
    
            .masha_slider{
                padding-top: 30px;
    
                padding-left: 33px;
                padding-right: 33px;
            }
        
        }
        .masha_slide_img{
            box-shadow: 0 1px 17px 0 rgba(0, 0, 0, 0.3);
            border-radius: 7px;
    
        }
    }
    
    
    
    
    
    
    
    
    .clients_choose{
        padding: 63px 0 126px 0 ;
        position: relative;
    
    
        .swiper-wrapper{
            display: flex;
            align-items: center;
        }
    
        .swiper-slide{
            height: 320px;
            padding: 0 20px;
        }
    
        .swiper-slide-prev{
            width: 25% !important;
        }
    
        .swiper-slide-active{
            width: 50% !important;
            height: 460px;
        }
    
        .swiper-slide-next{
            width: 25% !important;
        }
    
    }
    
    
    .clients_choose_item{
        height: 100%;
        width: 100%;
        position: relative;
        &__img{
            height: 100%;
            border-radius: 20px;
            overflow: hidden;
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        &__label{
            display: flex;
            position: absolute;
            top: 0px;
            padding: 20px;
    
            span{
                font-weight: 600;
                font-size: 18px;
                color: #fff;
                background: #7aa1c4;
                border-radius: 10px;
                padding: 10px 15px;
            }
        }
    }
    
    
    @media (max-width: 1300px) {
        .clients_choose{
        
            .swiper-slide{
                height: 210px;
            }
    
            .swiper-slide-active{
                height: 340px;
            }
        
        }
    }
    
    @media (max-width: 992px) {
    
    
    
        .clients_choose{
            padding: 50px 0 100px 0;
            overflow: hidden;
        
    
            .clients_choose_slider{
                padding-left: 40px;
                padding-right: 40px;
                overflow: visible;
            }
        
            .swiper-wrapper{
    
            }
        
            .swiper-slide{
                height: 215px;
                padding: 0 13px;
                // transition: .23s;
            }
        
            .swiper-slide-prev{
                width: 100% !important;
            }
        
            .swiper-slide-active{
                width: 100% !important;
                height: 308px;
            }
        
            .swiper-slide-next{
                width: 100% !important;
            }
        
        }
        
        
        .clients_choose_item{
            &__img{
                border-radius: 13px;
            }
            &__label{
                padding: 14px;
        
                span{
                    font-size: 12px;
                    border-radius: 7px;
                    padding: 7px 20px;
                }
            }
        }
        
    }
    
    
    @media (max-width: 640px) {
    
    
    
        .clients_choose{
            padding: 33px 0 66px 0;
    
            .clients_choose_slider{
                padding-left: 20px;
                padding-right: 20px;
            }
        
            .swiper-slide{
                height: 200px;
                padding: 0 7px;
            }
        
            .swiper-slide-active{
                height: 245px;
            }
        
        }
        
        
        .clients_choose_item{
            &__img{
                border-radius: 6px;
            }
            &__label{
                padding: 6.5px;
        
                span{
                    font-size: 8px;
                    border-radius: 3px;
                    padding: 4px 10px;
                }
            }
        }
        
    }
    @media (max-width: 400px) {
        .clients_choose{
        
            .swiper-slide{
                height: 140px;
            }
        
            .swiper-slide-active{
                height: 185px;
            }
        
        }
    
    }
    @media (max-width: 350px) {
        .clients_choose{
        
            .swiper-slide{
                height: 110px;
            }
        
            .swiper-slide-active{
                height: 155px;
            }
        
        }
    
    }
    
    
    
    
    
    
    
    
    
    
    
    // footer
    
    
    .footer{
        background-color: #EFE8DF;
        padding: 40px 0;
    
        .container{
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    
        &__logo{
            margin-bottom: 24px;
        }
    
    
        &__contact{
            margin-bottom: 15px;
            p{
                font-weight: 500;
                font-size: 18px;
                color: #4A4A4A;
                margin-bottom: 20px;
                text-align: center;
                a{
                    color: #4A4A4A;
                }
            }
        }
    
        &__social{
            display: flex;
            gap: 16px;
            a{
                display: flex;
                align-items: center;
                justify-content: center;
                img{
                    transition: 0.23s;
                    transform: scale(1);
                }
                &:hover{
                    img{
                        transform: scale(1.1);
                    }
                }  
            }
        }
    
        .login_btn{
            display: flex;
            align-items: center;
            justify-content: center;
            background: #a5a4a4;
            border-radius: 8px;
            padding: 10px;
            width: 175px;
            height: 44px;
            font-weight: 600;
            font-size: 20px;
            color: #fff;
            margin-top: 24px;
            transition: 0.23s;
            &:hover{
                background: #8f302b;
            }        
        }
    
    }
    
    
    @media (max-width: 640px) {
        .footer{    
            &__logo{
                margin-bottom: 10px;
                img{
                    width: 138px;
                }
            }
        
            &__contact{
                margin-bottom: 8px;
                p{
                    font-size: 9px;
                    margin-bottom: 10px;
                }
            }
        
            &__social{
                gap: 16px;
                img{
                    width: 20px;
                }
            }
        
            .login_btn{
                border-radius: 4px;
                padding: 6px 33px;
                width: 94px;
                height: 23px;            
                font-size: 10px;
                margin-top: 12px;
            }
        
        }
    }
}