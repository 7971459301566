

$red: #9B2626;


body {
    background-color: #fff;
    font-family: "Inter", sans-serif;
}

*{
    background-repeat: no-repeat;
    box-sizing: border-box;
}
html {
    scroll-behavior: smooth;
  }
.container{
    max-width: 1600px;
    margin: 0 auto;
    padding: 0 38px;
    @media screen and (max-width: 1600px){
        width: 100%;
        padding: 0 38px;
    }

    @media (max-width: 992px) {
        padding: 0 16px;
    }

    @media (max-width: 640px) {
        // padding: 0 8px;
    }
}

.whatsapp{
    position: fixed;
    right: 0;
    bottom: -7px;
    z-index: 20;
}

.wowSummit {

    .rs-picker-daterange .rs-input-group.rs-input-group-inside .rs-input {
        background-color: white;
      }
      
      .rs-input-group {
        border:  none !important;
        border-color: #fff;
      }
      
      .rs-picker-daterange .rs-input-group .rs-date-range-input:focus{
        border-color: #fff !important;
        outline: none;
        
      }
      .rs-input-group .rs-form-control-wrapper~.rs-input-group-addon, .rs-input-group input.rs-input~.rs-input-group-addon {
        background-color: white;
      }
      
      .rs-picker-daterange .rs-input-group .rs-date-range-input {
        background-color: white !important;
      }

    .autocomplete-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: 50px;
        background-color: #FFF !important;
        border-radius: 5px;
      }

      @media (min-width: 640px) and (max-width: 1100px) {
        .autocomplete-container {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 100%;
          height: 40px;
          background-color: #FFF !important;
          border-radius: 5px;
        }
        .autocomplete-input {
          width: 100%;
          box-sizing: border-box;
          height: 50px;
          background-color: #FFF !important;
          border-radius: 5px;
          padding-left: 40px;
          height: 40px;
          font-style: normal;
          font-family: 'Inter';
          font-size: 14px;
          font-weight: 300;
          line-height: 16.8px;
          outline: none;
          color: #4a4a4a;
        }
    }

    @media (min-width: 320px) and (max-width: 640px) {
        .autocomplete-container {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 100%;
          height: 40px;
          background-color: #FFF !important;
          border-radius: 5px;
        }
        .autocomplete-input {
          width: 100%;
          box-sizing: border-box;
          height: 50px;
          background-color: #FFF !important;
          border-radius: 5px;
          padding-left: 40px;
          height: 40px;
          font-style: normal;
          font-family: 'Inter';
          font-size: 14px;
          font-weight: 300;
          line-height: 16.8px;
          outline: none;
          color: #4a4a4a;
        }
    }
    .autocomplete-suggestions {
        list-style: none;
        /* margin: 0; */
        margin-top: 8px !important;
        border-radius: 5px !important;
        padding: 10 0px;
        /* border: 1px solid #ccc; */
        border-top: none;
        /* max-height: 200px; */
        overflow-y: auto;
        background-color: #fff;
        position: absolute;
        width: 100%;
        z-index: 1000;
      }
    .main_section{
        width: 100%;
        // max-height: 100vh;
    
        // overflow: hidden;
        position: relative;
    
        .mainimg{
            width: 100%;
        }
        .mainimg_mob{
            display: none;
        }
    
    
    
        &__wrap{
            position: absolute;
            margin-top: 106px;
            left: 0;
            right: 0;
            top: 0;
            height: 100%;
            width: 100%;
            left: 0;
            right: 0;
        }
    
        &__content{
            height: calc(100% - 106px);
            padding-top: 50px;
            position: relative;
            .container{
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
            }
        }
        
    
    
        .title{
            font-weight: 600;
            font-size: 44px;
            line-height: 170%;
            letter-spacing: 0.05em;
            text-transform: uppercase;
            text-align: center;
            color: #000;
        
            span{
                color: #fff;
                background-color: $red;
                padding: 7px 7px;
                border-radius: 8px;
        
            }
        }
    
        &__bottom{
            padding-bottom: 53px;
        }
        &__lblsss{
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 20px;
        }
        &__lbl{
            display: flex;
            align-items: center;
            font-weight: 500;
            font-size: 16px;
            color: #000;
            padding: 18px 20px;
            height: 56px;
            border-radius: 20px;
            box-shadow: 0 4px 44px 0 rgba(0, 0, 0, 0.3);
            background: #EFE8DF;
            gap: 10px;
            img{
                width: 24px;
            }
        }
    }
    
    .header{
        position: absolute;
        height: 106px;
        width: 100%;
        top: 0;
        border-bottom: 1px solid rgba(74, 74, 74, 0.3);
        .container{
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    
        .logo{
            img{
                width: 150px;
            }
        }
    }
    
    .main_menu{
        ul{
            display: flex;
            gap: 60px;
            li{
                a{
                    font-weight: 400;
                    font-size: 20px;
                    text-transform: uppercase;
                    color: #FFF;
                }
            }
        }
    }
    
    .burger-menu{
        display: none;
    }
    
    
    @media (max-width: 1280px) {
    
        .whatsapp{
            bottom: -32px;
            right: -23px;
        }
    
        .main_section{
            &__content{
                padding-top: 30px;
                height: calc(100% - 80px);
            }
    
            &__wrap{
                margin-top: 80px;
                .container{
                    min-height: 100%;
                }
            }
    
            .title{
                font-size: 32px;
            }
    
            &__bottom{
                padding-bottom: 30px;  
            }
        }
    
        .header{
            height: 80px;
        }
    }
    
    .main_menu_logo{
        display: none;
    }
    
    .main_menu{
        .burger-menu{
         z-index: 100;
        }
    }
    
    
    @media (max-width: 992px) {
    
        .whatsapp{
            svg{
                width: 110px;
            }
            bottom: -60px;
            right: -23px;
        }
        .main_section{
            &__content{
                padding-top: 28px;
                height: calc(100% - 63px);
            }
    
            &__wrap{
                margin-top: 63px;
                .container{
                    min-height: 100%;
                }
            }
    
            .title{
                font-size: 24px;
                span{
                    padding: 4px;
                    border-radius: 4px;
                }
            }
    
            &__bottom{
                padding-bottom: 15px;  
            }
    
            &__lblsss{
                gap: 8px;
            }
            &__lbl{
                font-size: 10px;
                padding: 5px 17px;
                height: 32px;
                border-radius: 8px;
                gap: 4px;
                img{
                    width: 9.6px;
                }
            }
        }
    
        .header{
            height: 63px;
    
            .logo{
                img{
                    width: 109px;
                }
            }
    
            .main_menu{
                position: fixed;
                opacity: 0;
                visibility: hidden;
                transition: 0.23s;
                width: 100vw;
                height: 100vh;
                background-color: #fff;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                z-index: 50;
                
                .burger-menu{
                    display: flex;
                    position: absolute;
                    top: 20px;
                    right: 20px;
                }
    
                .main_menu_logo{
                    display: flex;
                    width: 100px;
                    margin: 0 auto;
                    justify-content: center;
                    margin-top: 20px;
                    img{
                        width: 140px;
                    }
                }
    
                ul{
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    height: 100%;
                    gap: 40px;
    
                    margin-top: -30px;
                    li{
                        a{
                            font-size: 16px;
                        }
                    }
                }
            }
            .main_menu_show{
                opacity: 1;
                visibility: visible;
                transition: 300ms;
            }
        }
       
        .burger-menu {
            width: 25px;
            height: 13px;
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-end;
            cursor: pointer;
            z-index: 100;
        
            span {
                display: block;
                width: 100%;
                height: 1px;
                background-color: black;
                transition: transform 0.3s ease, opacity 0.2s ease, width 0.3s ease;
        
                &:nth-child(2) {
                    width: 14px;
                }
            }
        
            &.active {
                span:nth-child(1) {
                    transform: translateY(6px) rotate(45deg);
                    width: 100%;
                }
                span:nth-child(2) {
                    opacity: 0;
                }
                span:nth-child(3) {
                    transform: translateY(-6px) rotate(-45deg);
                    width: 100%;
                }
            }
        }
        
        
    
    }
    
    .speek{
        display: none;
    }
    
    .logo_mob{
        display: none;
    }
    
    @media (max-width: 640px) {
    
        .logo_desc{
            display: none;
        }
    
        .logo_mob{
            display: flex;
        }
    
        .whatsapp{
            svg{
                width: 120px;
            }
            bottom: -55px;
            right: -27px;
        }
        .main_section{
            max-height: 100svh !important;
            min-height: 400px !important;
            height: 100vh;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            .mainimg{
                bottom: 0;
                position: absolute;
            }
            .mainimg_desc{
                display: none;
            }
            .mainimg_mob{
                display: block;
                width: 100%;
                object-fit: cover;
                height: 100%;
            }
            &__content{
                // padding-top: 45px;
                height: calc(100% - 31px);
            }
    
            &__wrap{
                margin-top: 31px;
                .container{
                    min-height: 100%;
                }
            }
    
            .title{
                font-size: 24px;
                color: #fff;
                max-width: 330px;
                span{
                    padding: 2px;
                    border-radius: 4px;
                }
            }
    
            &__bottom{
                padding-bottom: 55px;  
            }
    
            &__lblsss{
                gap: 4px;
            }
            &__lbl{
                font-size: 10px;
                padding: 7px 6px;
                height: 24px;
                border-radius: 4px;
                background: transparent;
                border: 0.7px solid #EFE8DF;
                // box-shadow: none;
                text-align: center;
                color: #EFE8DF;
                img{
                    width: 13px;
                }
                &:nth-child(1){
                    border-left: 0.7px solid #EFE8DF !important;
                }
                &:nth-child(2){
                    gap: 3.4px;
                }
            }
        }
    
        .flxcol{
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .speek{
            display: block;
            background-color: #EFE8DF;
            padding: 6px 10px;
            border-radius: 4.3px;
            margin-top: 14px;
            font-size: 12px;
            color: #000000;
            font-weight: 600;
        }
    
        .header{
            height: 31px;
            padding: 10px 20px;
            // margin-top: 17px;
            border-bottom: none;
    
            .logo{
                img{
                    width: 100px;
                }
            }
    
            .main_menu{
    
                .burger-menu{
                    top: 22px;
                    right: 20px;
                }
    
                .main_menu_logo{
    
                    margin-top: 20px;
                    img{
                        width: 90px;
                    }
                }
    
                ul{
                    gap: 40px;
                    margin-top: -60px;
                    li{
                        a{
                            font-size: 12px;
                        }
                    }
                }
            }
    
        }
       
        .burger-menu {
            width: 24px;
            height: 14px;
            span {
                transition: transform 0.3s ease, opacity 0.2s ease, width 0.3s ease;
                background-color: #fff;
                &:nth-child(2) {
                    width: 12px;
                }
            }
        
            &.active {
                span{
                    background-color: #000;
                }
                span:nth-child(1) {
                    transform: translateY(6px) rotate(45deg);
                }
                span:nth-child(2) {
                }
                span:nth-child(3) {
                    transform: translateY(-6px) rotate(-45deg);
                }
            }
        }
        
        
    
    }
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    .clients_choose{
        padding: 126px 0 126px 0 ;
        position: relative;
    
    
        .swiper-wrapper{
            display: flex;
            align-items: center;
        }
    
        .swiper-slide{
            height: 320px;
            padding: 0 20px;
        }
    
        .swiper-slide-prev{
            width: 25% !important;
        }
    
        .swiper-slide-active{
            width: 50% !important;
            height: 460px;
        }
    
        .swiper-slide-next{
            width: 25% !important;
        }
    
    }
    
    
    .clients_choose_item{
        height: 100%;
        width: 100%;
        position: relative;
        &__img{
            height: 100%;
            border-radius: 20px;
            overflow: hidden;
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        &__label{
            display: flex;
            position: absolute;
            top: 0px;
            padding: 20px;
    
            span{
                font-weight: 600;
                font-size: 14px;
                color: #fff;
                background: #7aa1c4;
                border-radius: 10px;
                line-height: normal;
                padding: 10px 15px;
            }
        }
    }
    
    
    @media (max-width: 1300px) {
        .clients_choose{
        
            .swiper-slide{
                height: 210px;
            }
    
            .swiper-slide-active{
                height: 340px;
            }
        
        }
    }
    
    @media (max-width: 992px) {
    
    
    
        .clients_choose{
            padding: 50px 0 100px 0;
            overflow: hidden;
        
    
            .clients_choose_slider{
                padding-left: 40px;
                padding-right: 40px;
                overflow: visible;
            }
        
            .swiper-wrapper{
    
            }
        
            .swiper-slide{
                height: 215px;
                padding: 0 13px;
                // transition: .23s;
            }
        
            .swiper-slide-prev{
                width: 100% !important;
            }
        
            .swiper-slide-active{
                width: 100% !important;
                height: 308px;
            }
        
            .swiper-slide-next{
                width: 100% !important;
            }
        
        }
        
        
        .clients_choose_item{
            &__img{
                border-radius: 13px;
            }
            &__label{
                padding: 14px;
        
                span{
                    font-size: 12px;
                    border-radius: 7px;
                    padding: 7px 20px;
                }
            }
        }
        
    }
    
    
    @media (max-width: 640px) {
    
    
    
        .clients_choose{
            padding: 33px 0 66px 0;
    
            .clients_choose_slider{
                padding-left: 20px;
                padding-right: 20px;
            }
        
            .swiper-slide{
                height: 200px;
                padding: 0 7px;
            }
        
            .swiper-slide-active{
                height: 245px;
            }
        
        }
        
        
        .clients_choose_item{
            &__img{
                border-radius: 6px;
            }
            &__label{
                padding: 6.5px;
        
                span{
                    font-size: 8px;
                    border-radius: 3px;
                    padding: 4px 10px;
                }
            }
        }
        
    }
    @media (max-width: 400px) {
        .clients_choose{
        
            .swiper-slide{
                height: 140px;
            }
        
            .swiper-slide-active{
                height: 185px;
            }
        
        }
    
    }
    @media (max-width: 350px) {
        .clients_choose{
        
            .swiper-slide{
                height: 110px;
            }
        
            .swiper-slide-active{
                height: 155px;
            }
        
        }
    
    }

    .pagination{
        display: flex;
        justify-content: center;
        margin-top: 44px;
        gap: 10px;
        .swiper-pagination-bullet{
            margin-left: 0px !important;
            margin-right: 0px !important;
    
            border-radius: 100px;
            width: 30px;
            height: 10px;
            background: #dbdbdb;
            opacity: 1;        
            transition: 0.33s;
        }
    
        .swiper-pagination-bullet-active{
            background: #8f302b;
            width: 100px;
            transition: 0.33s;
        }
    }
    
    
    
    // VIP Package Page
    
    .vip-package {
        max-width: 1146px;
        margin: 0 auto;
        margin-top: 44px;
        border-radius: 18px;
        border-left: 0.5px solid rgba(0, 0, 0, 0.20);
        background: #FFF;
        box-shadow: 10px 10px 0px 0px rgba(0, 0, 0, 0.10);
    
        .slider-img {
            border-radius: 18px 18px 0px 0px;
        }
    
        .btn {
            border-radius: 10px;
            border: 2px solid #4A4A4A;
        }
    
        .profile {
            width: 100px;
            height: 100px;
            border-radius: 100%;
            border: 3px solid #4A4A4A;
            background: #FFF;
        }
    }
    
    
    
    // footer
    
    
    .footer{
        background-color: #EFE8DF;
        padding: 40px 0;
    
        .container{
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    
        &__logo{
            margin-bottom: 24px;
        }
    
    
        &__contact{
            margin-bottom: 15px;
            p{
                font-weight: 500;
                font-size: 18px;
                color: #4A4A4A;
                margin-bottom: 20px;
                text-align: center;
                a{
                    color: #4A4A4A;
                }
            }
        }
    
        &__social{
            display: flex;
            gap: 16px;
            a{
                display: flex;
                align-items: center;
                justify-content: center;
                img{
                    transition: 0.23s;
                    transform: scale(1);
                }
                &:hover{
                    img{
                        transform: scale(1.1);
                    }
                }  
            }
        }
    
        .login_btn{
            display: flex;
            align-items: center;
            justify-content: center;
            background: #a5a4a4;
            border-radius: 8px;
            padding: 10px;
            width: 175px;
            height: 44px;
            font-weight: 600;
            font-size: 20px;
            color: #fff;
            margin-top: 24px;
            transition: 0.23s;
            &:hover{
                background: #8f302b;
            }        
        }
    
    }
    
    
    @media (max-width: 640px) {
        .vip-package {
            margin-top: 32px;
            .profile {
                width: 60px;
                height: 60px;
                border-radius: 100%;
                border: 3px solid #4A4A4A;
                background: #FFF;
            }

            .slider-img {
                border-radius: 18px 18px 0px 0px;
                height: 191px;
                object-fit: cover;
            }
        }
        .footer{    
            &__logo{
                margin-bottom: 10px;
                img{
                    width: 138px;
                }
            }
        
            &__contact{
                margin-bottom: 8px;
                p{
                    font-size: 9px;
                    margin-bottom: 10px;
                }
            }
        
            &__social{
                gap: 16px;
                img{
                    width: 20px;
                }
            }
        
            .login_btn{
                border-radius: 4px;
                padding: 6px 33px;
                width: 94px;
                height: 23px;            
                font-size: 10px;
                margin-top: 12px;
            }
        
        }
    }
}