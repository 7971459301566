.input {
  width: 100%;
  height: 35px;
  // max-width: 544px;
  border: 1px solid #959595;
  border-radius: 5px;
  padding: 8px 12px;
  font-style: normal;
  font-family: 'Inter';
  font-size: 14px;
  font-weight: 300;
  line-height: 24px;
  outline: none;
  color: #4A4A4A;
  background-color: transparent;

  &:focus {
    // border: 1px solid black;
  }
  &:focus-visible {
    // border: 1px solid black;
    outline: none;
  }

  &::placeholder {
    color: #959595;
    font-size: 14px;
    font-family: 'Inter';
    font-weight: 300;
    line-height: 16.8px;
  }
}

.input-invalid {
  border: 1px solid #9B2626;
}

.label {
  display: block;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  font-family: 'Inter';
  line-height: 24px;
  color: #4A4A4A;
  margin-bottom: 8px;
}

.error-text {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17.81px;
  color: #9B2626;
  margin: 0;
  margin-top: 5px;
  text-align: left;
  font-family: 'Inter'
}

.success-text {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17.81px;
  color: #078D45;
  margin: 0;
  margin-top: 5px;
  text-align: left;
  font-family: 'Inter'
}

.input-disabled {
  @extend .input;
  background-color: rgba(0, 0, 0, 0.08);
  color: rgba(0, 0, 0, 0.48);
  margin: 0px !important;
  margin-bottom: 4px !important;
  pointer-events: none;
}

.label-for-personal-info {
  display: block;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  font-family: 'Inter';
  line-height: 18px;
  color: #1F2937;
  margin-bottom: 8px;
}
