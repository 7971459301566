.input-fields {
    background-color: #EFE8DF;
    // height: 60px;
    & div {
        & input {
            border: none;
            font-style: normal;
            border-radius: 5px;

        }
    }
}

// .selector-wrapper .css-b62m3t-container .select-drop-arrow__control {
//             background-color: transparent !important;
// }
// .selector-wrapper {
//     & div {
//         & div{
//             background-color: #EFE8DF !important;
//         }
//     }
// }

.guests-menu-card {
    // scrollbar-width: auto;
    // scrollbar-color: #A7AAA9 #F5F5F5;
    // -ms-overflow-style: none;
    overflow-y: auto;
    max-height: 448px;

  }
  
  /* Chrome, Edge, and Safari */
  .guests-menu-card::-webkit-scrollbar {
    width: 16px;
    height: 8px;
    // height: 648px;
  }

  
  /* Chrome, Edge, and Safari */
  .guests-menu-card::-webkit-scrollbar {
    width: 6px;
    height: 8px;
    // display: none;
  }
  
  .guests-menu-card::-webkit-scrollbar-track {
    background: #EFE8DF;
    // border-radius: 16px;
  }
  
  .guests-menu-card::-webkit-scrollbar-thumb {
    min-height: 40px;
    background-color: #4A4A4A;
    border-radius: 16px;
    border: 3px solid #4A4A4A;
  }

  // .date-range-picker-booking {
  //   // background-color: red !important;
  //   .rs-picker-daterange {
  //     background-color: red !important;

  //     .rs-input-group {
  //       background-color: #EFE8DF !important;
  //       height: 100%;
  //     }
      
  //   }
  // }
    

  // .date-range-picker-booking {
  //   background-color: red;
  // }