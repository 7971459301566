.company-avatar {
  &-xs {
    width: 24px;
    height: 24px;
    border-radius: 4px;
  }

  &-sm {
    width: 32px;
    height: 32px;
    border-radius: 4px;
  }

  &-md {
    width: 40px;
    height: 40px;
    border-radius: 4px;
  }

  &-lg {
    width: 48px;
    height: 48px;
    border-radius: 4px;
  }

  &-xl {
    width: 96px;
    height: 96px;
    border-radius: 4px;
  }


  &-2xl {
    width: 100%;
    height: 96px;
    border-radius: 4px;
  }
}
