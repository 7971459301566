.icon-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #00000014;

  &-xs {
    padding: 5px;
    border-radius: 4px;
    width: 24px;
    height: 24px;
  }

  &-sm {
    padding: 8px;
    border-radius: 6px;
    width: 32px;
    height: 32px;
  }

  &-md {
    padding: 10px;
    border-radius: 8px;
    width: 40px;
    height: 40px;
  }

  &-lg {
    padding: 8px;
    border-radius: 4px;
    width: 48px;
    height: 48px;
  }
  &-x-lg {
    padding: 30px;
    border-radius: 12px;
    width: 96px;
    height: 96px;
  }
}
